import React, {type FC} from 'react';
import {PageTitle} from '../../../_metronic/layout/core';
import {HolidaysTable} from './HolidaysTable';
import {KTCard} from '../../../_metronic/helpers';

const HolidaysPage: FC = () => (
	<>
		<PageTitle breadcrumbs={[]}>Tatil Günü İşlemleri</PageTitle>
		<KTCard>
			<HolidaysTable/>
		</KTCard>
	</>
);

export {HolidaysPage};
