import React, {type FC, useEffect, useState} from 'react';
import {KTSVG} from '../../../../../_metronic/helpers';
import {addNewHoliday} from '../../core/requests';
import Swal from 'sweetalert2';

const AddHolidayDrawer: FC = () => {
	const [holiday, setHoliday] = useState({
		holidayMonth: 1,
		holidayDay: 1,
		holidayYear: 2023,
	});
	const [loading, setLoading] = useState(false);

	const clearData = () => {
		setHoliday({
			holidayMonth: 1,
			holidayDay: 1,
			holidayYear: 2023,
		});
	};

	const postData = () => {
		if (!holiday.holidayMonth || !holiday.holidayDay || !holiday.holidayYear) {
			void Swal.fire({
				title: 'Hata Oluştu',
				text: 'Tatil günü verileri boş geçilemez',
				icon: 'error',
				confirmButtonText: 'Tamam',
			});
		}

		setLoading(true);
		addNewHoliday(holiday).then(response => {
			void Swal.fire({
				title: 'Başarılı',
				text: 'Tatil Günü başarıyla eklendi. Sistemin yenilenmesi için sayfayı yenileyin.',
				icon: 'success',
				cancelButtonText: 'Daha Sonra',
				confirmButtonText: 'Sayfayı Yenile',
				showCancelButton: true,
			}).then(result => {
				if (result.isConfirmed) {
					window.location.reload();
				}
			});

			clearData();
			setLoading(false);
		}).catch(error => {
			console.log(error);
			void Swal.fire({
				title: 'Hata Oluştu',
				text: `Tatil Günü eklenirken hata oluştu. \n Hata: ${error as string}`,
				icon: 'error',
				confirmButtonText: 'Tamam',
			});

			setLoading(false);
		});
	};

	return (
		<>
			<div
				id='kt_addholiday'
				className='bg-body'
				data-kt-drawer='true'
				data-kt-drawer-name='activities'
				data-kt-drawer-activate='true'
				data-kt-drawer-overlay='true'
				data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
				data-kt-drawer-direction='end'
				data-kt-drawer-toggle='#kt_addholiday_toggle'
				data-kt-drawer-close='#kt_addholiday_close'
			>
				<div className='card  w-100 shadow-none rounded-0'>
					<div className='card-header' id='kt_activities_header'>
						<h3 className='card-title fw-bolder text-dark'>Yeni Tatil Günü</h3>
						<div className='card-toolbar'>
							<button
								type='button'
								className='btn btn-sm btn-icon btn-active-light-primary me-n5'
								id='kt_addholiday_close'
							>
								<KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
							</button>
						</div>
					</div>
					<div className='card-body  m-2 position-relative' id='kt_activities_body'>
						<div
							id='kt_activities_scroll'
							className='position-relative  me-n5 pe-5'
							data-kt-scroll='true'
							data-kt-scroll-height='auto'
							data-kt-scroll-wrappers='#kt_activities_body'
							data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
							data-kt-scroll-offset='5px'
						>
							<div className='timeline'>
								<form id='kt_modal_add_user_form' className='form' noValidate>
									<div
										className='d-flex flex-column  me-n7 pe-7'
										id='kt_modal_add_user_scroll'
										data-kt-scroll='true'
										data-kt-scroll-activate='{default: false, lg: true}'
										data-kt-scroll-max-height='auto'
										data-kt-scroll-dependencies='#kt_modal_add_user_header'
										data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
										data-kt-scroll-offset='300px'
									>
										<div className='adduserdivs'>
											<div className='fv-row mb-7 w-100 '>
												<label className='required fw-bold fs-6 mb-2 w-100'>Ay</label>
												<select
													className='form-select'
													aria-label='Şehir'
													onChange={e => {
														setHoliday({...holiday, holidayMonth: Number(e.target.value)});
													}}
												>
													<option value='1'>Ocak</option>
													<option value='2'>Şubat</option>
													<option value='3'>Mart</option>
													<option value='4'>Nisan</option>
													<option value='5'>Mayıs</option>
													<option value='6'>Haziran</option>
													<option value='7'>Temmuz</option>
													<option value='8'>Ağustos</option>
													<option value='9'>Eylül</option>
													<option value='10'>Ekim</option>
													<option value='11'>Kasım</option>
													<option value='12'>Aralık</option>
												</select>
											</div>
										</div>
										<div className='adduserdivs'>
											<div className='fv-row mb-7 w-100 '>
												<label className='required fw-bold fs-6 mb-2 w-100'>Gün</label>
												<select
													className='form-select'
													aria-label='Gün'
													onChange={e => {
														setHoliday({...holiday, holidayDay: Number(e.target.value)});
													}}
												>
													<option value='1'>1</option>
													<option value='2'>2</option>
													<option value='3'>3</option>
													<option value='4'>4</option>
													<option value='5'>5</option>
													<option value='6'>6</option>
													<option value='7'>7</option>
													<option value='8'>8</option>
													<option value='9'>9</option>
													<option value='10'>10</option>
													<option value='11'>11</option>
													<option value='12'>12</option>
													<option value='13'>13</option>
													<option value='14'>14</option>
													<option value='15'>15</option>
													<option value='16'>16</option>
													<option value='17'>17</option>
													<option value='18'>18</option>
													<option value='19'>19</option>
													<option value='20'>20</option>
													<option value='21'>21</option>
													<option value='22'>22</option>
													<option value='23'>23</option>
													<option value='24'>24</option>
													<option value='25'>25</option>
													<option value='26'>26</option>
													<option value='27'>27</option>
													<option value='28'>28</option>
													<option value='29'>29</option>
													<option value='30'>30</option>
													<option value='31'>31</option>
												</select>
											</div>
										</div>
										<div className='adduserdivs'>
											<div className='fv-row mb-7 w-100 '>
												<label className='required fw-bold fs-6 mb-2 w-100'>Yıl</label>
												<input
													type='text'
													className='form-control'
													placeholder='Yıl'
													value={holiday.holidayYear}
													onChange={e => {
														setHoliday({...holiday, holidayYear: Number(e.target.value)});
													}}
												/>
											</div>
										</div>

										<div className='d-flex justify-content-end mb-5' >
											<button type='button' onClick={postData} className='btn btn-primary mb-5' disabled={loading}>
												{
													loading ? (
														<>
															<span>Kayıt ediliyor...</span>
															<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
														</>
													) : (
														<>
															Kaydet
														</>
													)
												}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	);
};

export {AddHolidayDrawer};
