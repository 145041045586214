import React, {type FC} from 'react';

const ShortCodesModal: FC = () => (
	<>
		<div className='modal fade' tabIndex={-1} id='kt_modal_shortcodes'>
			<div className='modal-dialog'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title'>Kısaltma Listesi</h5>
					</div>
					<div className='modal-body'>
						<table className='table table-striped table-row-bordered gy-5 gs-7 border rounded'>
							<thead>
								<tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
									<th className='min-w-125px'>Kısaltma</th>
									<th className='min-w-125px'>Açıklama</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<code>{'{{personel_adi}}'}</code>
									</td>
									<td>
										<span className='badge badge-light fw-bolder fs-8 px-2 py-1 ms-2'>
                      Personel Adı
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<code>{'{{proje_adi}}'}</code>
									</td>
									<td>
										<span className='badge badge-light fw-bolder fs-8 px-2 py-1 ms-2'>
                      Proje Adı
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<code>{'{{enlem}}'}</code>
									</td>
									<td>
										<span className='badge badge-light fw-bolder fs-8 px-2 py-1 ms-2'>
                      Personelin giriş yaptığı enlem
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<code>{'{{boylam}}'}</code>
									</td>
									<td>
										<span className='badge badge-light fw-bolder fs-8 px-2 py-1 ms-2'>
                      Personelin giriş yaptığı boylam
										</span>
									</td>
								</tr>
								<tr>
									<td>
										<code>{'{{tarih}}'}</code>
									</td>
									<td>
										<span className='badge badge-light fw-bolder fs-8 px-2 py-1 ms-2'>
                      Personelin giriş/çıkış yaptığı tarih
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className='modal-footer'>
						<button
							type='button'
							className='btn btn-light'
							data-bs-dismiss='modal'
						>
              Kapat
						</button>
					</div>
				</div>
			</div>
		</div>
	</>
);

export {ShortCodesModal};
