import React, {type FC, useEffect, useMemo, useState} from 'react';
import {KTSVG} from '../../../_metronic/helpers';
import {deleteHoliday, getHolidays} from './core/requests';
import {
	type ID,
	initialListView,
} from '../../../_metronic/helpers';
import {type Holiday} from './core/models';
import Swal from 'sweetalert2';
import {HolidaysListLoading} from './components/Loading/HolidaysListLoading';
import {type PagingData} from '../users/core/models';
import {DrawerComponent} from '../../../_metronic/assets/ts/components';
import {Pagination} from '../../../_metronic/helpers/components/Pagination';
import {Link, useParams} from 'react-router-dom';
const HolidaysTable: FC = () => {
	const params = useParams();
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState<ID[]>(initialListView.selected);
	const [page, setPage] = useState(1);
	const [data, setData] = useState([] as Holiday[]);
	const [pagingData, setPagingData] = useState({} as PagingData);
	const [drawerShowed, setDrawerShowed] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const [search, setSearch] = useState('');
	const [fetchQuery, setFetchQuery] = useState('');
	const [selectedId, setSelectedId] = useState(0);

	useEffect(() => {
		fetchPage();
	}, [page, fetchQuery]);

	useEffect(() => {
		if (params.city) {
			setFetchQuery(`&city=${params.city}`);
		}
	}, [params]);
	const fetchPage = () => {
		setLoading(true);
		getHolidays(`page=${page}${fetchQuery}`).then(response => {
			console.log(response);
			if (response.status === 'success') {
				setData(response.data);
			} else {
				void Swal.fire({
					title: 'Hata Oluştu',
					text: 'Tatil Günüler listelenirken hata oluştu.',
					icon: 'error',
					confirmButtonText: 'Tamam',
				});
			}

			setLoading(false);
		}).catch(error => {
			void Swal.fire({
				title: 'Hata Oluştu',
				text: `Tatil Günüler listelenirken hata oluştu. \n Hata: ${error as string}`,
				icon: 'error',
				confirmButtonText: 'Tamam',
			});

			setLoading(false);
		});
	};

	const onSearch = () => {
		if (searchInput.length < 3) {
			void Swal.fire({
				title: 'Bilgi',
				text: 'Arama yapabilmek için en az 3 karakter girmelisiniz.',
				icon: 'info',
				confirmButtonText: 'Tamam',
			});

			return;
		}

		setSearch(searchInput);
		setPage(1);
		setFetchQuery(`&search=${searchInput}`);
	};

	const clearSearch = () => {
		setSearch('');
		setSearchInput('');
		setPage(1);
		setFetchQuery('');
	};

	const drawerCloseListener = () => {
		if (!drawerShowed) {
			setDrawerShowed(true);

			const addUserDrawer = DrawerComponent.getInstance('kt_addholiday');

			addUserDrawer?.on('kt.drawer.hide', () => {
				fetchPage();
			});
		}
	};

	const prevPage = () => {
		setPage(page - 1);
	};

	const nextPage = () => {
		setPage(page + 1);
	};

	const clearSelected = () => {
		setSelected([]);
	};

	const deletePost = (id: number) => {
		void Swal.fire({
			title: 'Tatili Sil',
			text: 'Tatili silmek istediğinize emin misiniz?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Evet',
			cancelButtonText: 'Hayır',
		}).then(result => {
			if (result.isConfirmed) {
				setLoading(true);
				deleteHoliday(id).then(response => {
					if (response.status === 'success') {
						void Swal.fire({
							title: 'Başarılı',
							text: 'Tatil başarıyla silindi.',
							icon: 'success',
							confirmButtonText: 'Tamam',
						});
						fetchPage();
					} else {
						void Swal.fire({
							title: 'Hata Oluştu',
							text: 'Tatil silinirken hata oluştu.',
							icon: 'error',
							confirmButtonText: 'Tamam',
						});
					}
				}).catch(error => {
					void Swal.fire({
						title: 'Hata Oluştu',
						text: `Tatil silinirken hata oluştu. \n Hata: ${error as string}`,
						icon: 'error',
						confirmButtonText: 'Tamam',
					});
				});
			}
		});
	};

	return (
		<>
			<div className='card'>
				<div className='card-header border-0 pt-4'>
					<div className='d-flex align-items-center position-relative my-1'>
						<div className='d-flex align-items-center position-relative my-1'>
							<KTSVG
								path='/media/icons/duotune/general/gen021.svg'
								className='svg-icon-1 position-absolute ms-6'
							/>
							<input
								type='text'
								data-kt-user-table-filter='search'
								className='form-control form-control-solid w-250px ps-14'
								placeholder='Tatil Günü Ara'
								value={searchInput}
								onChange={e => {
									setSearchInput(e.target.value);
								}}
							/>
							<button type='button' className='btn btn-light-primary ms-5' onClick={onSearch}>
								Ara
							</button>
						</div>
					</div>

					<div className='card-toolbar'>
						<div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
							<div id='kt_addholiday_toggle'>
								<button type='button' className='btn btn-primary' onClick={drawerCloseListener}>
									<KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
									Tatil Günü Ekle
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className='card-body py-3 mt-5'>

					{search !== '' && (
						<div className='d-flex flex-column pb-5 text-dark'>
							<span><b><i>{search}</i></b> için arama sonuçları :</span>
							<a href='#' onClick={clearSearch}>Aramayı temizle</a>
						</div>
					)}

					<div className='table-responsive'>
						<table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
							<thead>
								<tr className='fw-bold text-muted'>
									<th className='min-w-100px'>Gün</th>
									<th className='min-w-100px'>Ay</th>
									<th className='min-w-100px'>Yıl</th>
									<th className='min-w-120px text-end'>İşlemler</th>
								</tr>
							</thead>
							<tbody>
								{data.map(item => (
									<tr key={item.id}>
										<td>
											<span className='text-dark fs-6'>
												{item.holidayDay}
											</span>
										</td>
										<td>
											<span className='text-dark fs-6'>
												{item.holidayMonth}
											</span>
										</td>
										<td>
											<span className='text-dark fs-6'>
												{item.holidayYear}
											</span>
										</td>
										<td className='text-end'>

											<a
												href='#'
												data-bs-toggle='modal'
												data-bs-target='#kt_modal_edit_holiday'
												className='badge badge-danger fw-bolder fs-8 px-2 py-1 ms-2 cursor-pointer'
												onClick={() => {
													deletePost(item.id ?? 0);
												}}
											>
												Sil
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						{loading && <HolidaysListLoading />}
						<Pagination pagingData={pagingData} currentPage={page} prevPage={prevPage} nextPage={nextPage} setPage={newPage => {
							setPage(newPage);
						}}/>
					</div>
				</div>
			</div>
		</>
	);
};

export {HolidaysTable};
