import axios, {type AxiosResponse} from 'axios';
import {
	type HolidaysQueryResponse,
} from './models';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const apiUrl = process.env.REACT_APP_API_URL.toString();
const getHolidaysUrl = `${apiUrl}/api/admin/getHolidays`;
const addHolidayUrl = `${apiUrl}/api/admin/addHoliday`;
const deleteHolidayUrl = `${apiUrl}/api/admin/deleteHolidayById`;

const getHolidays = async (query: string) => axios
	.get(`${getHolidaysUrl}?${query}`)
	.then((response: AxiosResponse<HolidaysQueryResponse>) => response.data);

const addNewHoliday = async query => axios
	.post(`${addHolidayUrl}`, query)
	.then((response: AxiosResponse<HolidaysQueryResponse>) => response.data);

const deleteHoliday = async (id: number) => axios
	.post(`${deleteHolidayUrl}`, {id})
	.then((response: AxiosResponse<HolidaysQueryResponse>) => response.data);

export {getHolidays, addNewHoliday, deleteHoliday};
