import {type FC, useEffect, useRef, useState} from 'react';
import {KTSVG} from '../../../../_metronic/helpers';
import React from 'react';
import {parseISO} from 'date-fns';
import Swal from 'sweetalert2';
import {type PatientForm} from '../core/models';
import moment from 'moment/moment';
import {type AllProject} from '../../users/core/models';
import {ProjectFilterSelect} from '../../../../_metronic/helpers/components/ProjectFilterSelect';
import {getPatientFormsByDate} from '../core/requests';
import {useDownloadExcel} from 'react-export-table-to-excel';

const ExcelDownloadModal: FC<{allProjects: AllProject[]}> = ({allProjects}) => {
	const [loading, setLoading] = useState(false);
	const [downloadReady, setDownloadReady] = useState(false);
	const [data, setData] = useState([] as PatientForm[]);
	const [date1, setDate1] = useState('');
	const [date2, setDate2] = useState('');
	const tableRef = useRef<HTMLTableElement>(null);
	const [projectId, setProjectId] = useState(0);
	const {onDownload} = useDownloadExcel({
		currentTableRef: tableRef.current,
		filename: 'Raporlar Tablosu',
		sheet: 'Raporlar Tablosu',
	});

	const handleProcess = () => {
		if (date1 === '' || date2 === '') {
			void Swal.fire({
				title: 'Bilgi',
				text: 'Lütfen tarihleri seçiniz.',
				icon: 'info',
				confirmButtonText: 'Tamam',
			});

			return;
		}

		if (loading) {
			return;
		}

		if (downloadReady) {
			onDownload();
			setDownloadReady(false);
			return;
		}

		setLoading(true);

		getPatientFormsByDate(`reportDate1=${date1}&reportDate2=${date2}&projectId=${projectId}`)
			.then(response => {
				if (response.status === 'success') {
					setData(response.data);
					setDownloadReady(true);
				} else {
					void Swal.fire({
						title: 'Hata Oluştu',
						text: 'Raporlar listelenirken hata oluştu.',
						icon: 'error',
						confirmButtonText: 'Tamam',
					});
				}

				setLoading(false);
			})
			.catch(error => {
				void Swal.fire({
					title: 'Hata Oluştu',
					text: `Raporlar listelenirken hata oluştu. \n Hata: ${error as string}`,
					icon: 'error',
					confirmButtonText: 'Tamam',
				});

				setLoading(false);
			});
	};

	const reportDate1Handle = e => {
		if (parseISO(e.target.value as string) >= parseISO(date2)) {
			void Swal.fire({
				title: 'Bilgi',
				text: 'ilk tarih, ikinci tarihten büyük olamaz.',
				icon: 'info',
				confirmButtonText: 'Tamam',
			});
			return;
		}

		setDate1(e.target.value as string);
	};

	const reportDate2Handle = e => {
		if (parseISO(e.target.value as string) <= parseISO(date1)) {
			void Swal.fire({
				title: 'Bilgi',
				text: 'İkinci tarih, ilk tarihten küçük olamaz.',
				icon: 'info',
				confirmButtonText: 'Tamam',
			});
			return;
		}

		setDate2(e.target.value as string);
	};

	const handleProjectFilter = option => {
		if (option.value === 0 || option.value === '0' || option.value === undefined) {
			setProjectId(0);
			return;
		}

		setProjectId(option.value as number);
	};

	return (
		<>
			<div className='modal fade' tabIndex={-1} id='kt_excel_download_modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>

						<div className='modal-header'>
							<h5 className='modal-title'>Excel Çıktısı Oluştur</h5>
							<div
								className='btn btn-icon btn-sm btn-active-light-primary ms-2'
								data-bs-dismiss='modal'
								aria-label='Close'
							>
								<KTSVG
									path='/media/icons/duotune/arrows/arr061.svg'
									className='svg-icon svg-icon-2x'
								/>
							</div>
						</div>
						<div className='modal-body'>
							<div className='mb-5 d-flex flex-column w-100'>
								<label className='form-label'>Proje</label>
								<ProjectFilterSelect
									allProjects={allProjects}
									onProjectChange={handleProjectFilter}
									showAllProjects={true}
								/>
							</div>
							<label className='form-label'>Tarih Aralığı</label>
							<div className='flex-row position-relative w-100'>
								<div className=' gap-4 pt-1 '>
									<div className='d-flex gap-4 pt-1 align-items-center'>
										<input type='date' className='datecss w-100' value={date1} onChange={reportDate1Handle}/>
										<span>to</span>
										<input type='date' className='datecss w-100' value={date2} onChange={reportDate2Handle}/>
									</div>
								</div>
							</div>

							<table ref={tableRef} className='table table-row-bordered donotshow'>
								<thead>
									<tr className='fw-bold text-muted'>
										<th>HASTANIN ADI SOYADI</th>
										<th>HASTANIN TC KİMLİK NO</th>
										<th>HASTA İLETİŞİM</th>
										<th>FİRMA/ZİYARETCİ</th>
										<th>BAŞVURU TÜRÜ</th>
										<th>MÜDAHALEYİ YAPAN SAĞLIK PERSONELİ</th>
										<th>HASTA VAKA / ŞİKAYET</th>
										<th>ŞİKAYETE KONU OLAY BİLGİSİ (Şikayet Öyküsü)</th>
										<th>YAPILAN MÜDAHALE</th>
										<th>KULLANILAN SARF MALZEME</th>
										<th>PROJE</th>
										<th>OLUŞTURULMA TARİHİ</th>
									</tr>
								</thead>
								<tbody>
									{data.map((item, index) => (
										<tr key={index}>
											<td>
												{item.patientName}
											</td>
											<td>
												{item.patientIdentityNumber}
											</td>
											<td>
												{item.patientContact}
											</td>
											<td>
												{item.patientType}
											</td>
											<td>
												{item.patientApplicationType}
											</td>
											<td>
												{item.personalName}
											</td>
											<td>
												{item.patientComplaint}
											</td>
											<td>

											</td>
											<td>
												{item.patientIntervention}
											</td>
											<td>

											</td>
											<td>
												{allProjects.find(ap => ap.id === item.projectId)?.name}
											</td>
											<td>
												{moment(item.date).format('DD.MM.YYYY HH:mm')}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn btn-light'
								data-bs-dismiss='modal'
							>
                Kapat
							</button>
							<button
								type='button'
								className='btn btn-primary'
								onClick={handleProcess}
								disabled={loading}
							>
								{
									loading ? (
										<>
											<span>Veriler getiriliyor...</span>
											<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
										</>
									) : downloadReady ? (
										'İndir'
									) : (
										'Verileri Getir'
									)
								}
							</button>
						</div>

					</div>
				</div>
			</div>
		</>
	);
};

export {ExcelDownloadModal};
