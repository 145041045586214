/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  loading?: boolean
  href?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  loading = false,
  href = '#',
}) => {
  return (
    <a href={href} className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body position-relative'>
        <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />
        {loading ? (
          <>
            <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>-</div>
            <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
            <div className="loading-overlay">
              <span className='spinner-border align-middle ms-2'></span>
            </div>
          </>
        ) : (
          <>
            <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>{title}</div>
            <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
          </>
        )}

      </div>
    </a>
  )
}

export {StatisticsWidget5}
